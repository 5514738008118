@import "../../styles/variables";
@import "../../styles/mixins/px-to-rem";

.root {
  background-size: 200% auto;
  border: 1px $white solid;
  border-radius: 58px;
  color: $white;
  cursor: pointer;
  font-family: Poppins, sans-serif;
  font-size: px-to-rem(16px);
  font-weight: 600;
  padding: 16px 48px;
  transition: $transition-duration;

  &.disabled {
    cursor: default;
    opacity: 0.2;
  }
}

.root:hover {
  background-color: $white;
  color: $black;
}
