@import "../../styles/mixins/respond-to";

.root {
  left: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  right: 50%;
  width: 100vw;

  @include respond-to("md") {
    left: 0;
    margin-left: auto;
    margin-right: auto;
    right: 0;
    width: auto;
  }
}

.button {
  cursor: pointer;
  margin-top: -25px;
  position: absolute;
  top: 50%;
  visibility: hidden;
  z-index: 10;

  @include respond-to("md") {
    visibility: visible;
  }

  &.prev {
    left: 10px;
    right: auto;
  }

  &.next {
    left: auto;
    right: 10px;
  }

  &.disabled {
    opacity: 0.5;
  }
}

.pagination {
  text-align: center;

  @include respond-to("md") {
    display: none;
  }

  .bullet {
    background-color: #363636;
    border-radius: 50%;
    display: inline-block;
    height: 6px;
    margin: 0 6px;
    transition: background-color 300ms ease-out;
    vertical-align: middle;
    width: 6px;
  }

  .active {
    background-color: $white;
    height: 8px;
    opacity: 0.8;
    width: 8px;
  }
}
