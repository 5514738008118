@import "../../styles/variables";
@import "../../styles/mixins/px-to-rem";

$border-radius: 12px;

.image {
  border-radius: $border-radius;
  width: 100%;
}

.gradient {
  background: linear-gradient(360deg, rgb(0 0 0 / 80%) 0.5%, rgb(0 0 0 / 63.5%) 9%, rgb(0 0 0 / 0%) 33%);
  border-radius: $border-radius;
  inset: 0;
  position: absolute;
}

.label {
  background-color: $yellow;
  border-radius: 58px;
  color: $dense-balck;
  font-family: Poppins, sans-serif;
  font-size: px-to-rem(11px);
  font-weight: 700;
  left: 25px;
  padding: 6px 12px;
  position: absolute;
  top: 25px;
}

.title {
  bottom: 25px;
  font-family: Poppins, sans-serif;
  font-size: px-to-rem(22px);
  font-weight: 600;
  left: 25px;
  position: absolute;
}

.extended-title {
  bottom: 25px;
  left: 25px;
  position: absolute;

  .heading {
    font-family: Poppins, sans-serif;
    font-size: px-to-rem(22px);
    font-weight: 600;
    margin-bottom: 10px;
  }

  .amount {
    font-family: Poppins, sans-serif;
    font-size: px-to-rem(16px);
    font-weight: 600;
  }
}

.price {
  bottom: 25px;
  font-size: px-to-rem(18px);
  line-height: 1;
  position: absolute;
  right: 25px;
}
