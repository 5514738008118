@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import "styles/fonts/BebasNeue/bebas-neue";
@import "styles/variables";

* {
  box-sizing: border-box;
}

body {
  background-color: $black;
  color: $white;
  font-family: Poppins, sans-serif;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

ol,
ul,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

button {
  background: none;
  border: 0;
  outline: none;
  padding: 0;
}

a {
  text-decoration: none;
}
