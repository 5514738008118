@import "../../styles/variables";
@import "../../styles/mixins/respond-to";
@import "../../styles/mixins/px-to-rem";

.root {
  position: relative;

  &.without-button {
    padding-bottom: 115px;
  }
}

.info {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;

  &.offset {
    margin-top: 75px;

    @include respond-to("lg") {
      margin-top: 120px;
    }
  }

  &.top-offset {
    margin-top: 55px;

    @include respond-to("lg") {
      margin-top: 120px;
    }
  }

  @include respond-to("lg") {
    flex-direction: row;
    margin-bottom: 56px;
  }

  .title {
    font-family: "Bebas Neue", sans-serif;
    font-size: px-to-rem(70px);
    font-weight: 700;
    margin-bottom: 20px;
    word-wrap: normal;

    @include respond-to("lg") {
      flex-basis: 50%;
      margin-bottom: 0;
    }
  }

  .description {
    font-family: Poppins, sans-serif;
    font-size: px-to-rem(16px);
    font-weight: 400;
    margin-bottom: 40px;

    @include respond-to("lg") {
      flex-basis: 50%;
      margin-bottom: 0;
    }
  }

  .blue {
    color: $blue;
  }

  .red {
    color: $red;
  }

  .yellow {
    color: $yellow;
  }
}

.btn-wrapper {
  margin-bottom: 115px;
  margin-top: 65px;
  position: relative;
  text-align: center;
  z-index: 1;

  @include respond-to("lg") {
    margin-top: 110px;
  }
}

.background {
  @include respond-to("lg") {
    background: linear-gradient(180deg, $black 0%, $dark-grey 100%);
    bottom: 0;
    left: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    position: absolute;
    right: 50%;
    top: 0;
    width: 100vw;
  }
}

.ornament {
  background-repeat: no-repeat;
  background-size: cover;
  left: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  position: relative;
  right: 50%;
  width: 100vw;
}
