@import "../../styles/variables";
@import "../../styles/mixins/respond-to";
@import "../../styles/mixins/px-to-rem";

.root {
  width: 100%;

  .image-wrapper {
    margin-bottom: 40px;
    margin-top: 80px;

    @include respond-to("lg") {
      margin-bottom: 56px;
      margin-top: 110px;
    }

    .image {
      width: 100%;
    }
  }

  .description {
    font-family: "Bebas Neue", sans-serif;
    font-size: px-to-rem(29px);
    font-weight: 700;
    margin-bottom: 40px;

    @include respond-to("lg") {
      font-size: px-to-rem(37px);
    }

    p {
      margin-bottom: 10px;
      text-align: center;
    }
  }

  .tag-wrapper {
    font-family: Poppins, sans-serif;
    font-size: px-to-rem(18px);
    font-weight: 600;
    margin-bottom: 130px;
    text-align: center;

    @include respond-to("lg") {
      margin-bottom: 228px;
    }

    .tag {
      border: 2px solid $yellow;
      border-radius: 60px;
      color: $yellow;
      display: inline-block;
      padding: 8px 24px;
    }
  }
}
