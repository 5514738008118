@font-face {
  font-family: "Bebas Neue";
  font-style: normal;
  font-weight: 100;
  src: url("./OTF/BebasNeue-Thin.otf") format("OpenType"), url("./TTF/BebasNeue-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Bebas Neue";
  font-style: normal;
  font-weight: 200;
  src: url("./OTF/BebasNeue-Light.otf") format("OpenType"), url("./TTF/BebasNeue-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Bebas Neue";
  font-style: normal;
  font-weight: 300;
  src: url("./OTF/BebasNeue-Book.otf") format("OpenType"), url("./TTF/BebasNeue-Book.ttf") format("truetype");
}

@font-face {
  font-family: "Bebas Neue";
  font-style: normal;
  font-weight: 400;
  src: url("./OTF/BebasNeue-Regular.otf") format("OpenType"), url("./TTF/BebasNeue-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Bebas Neue";
  font-style: normal;
  font-weight: 700;
  src: url("./OTF/BebasNeue-Bold.otf") format("OpenType"), url("./TTF/BebasNeue-Bold.ttf") format("truetype");
}
