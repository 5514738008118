// colors
$white: #fff;
$secondary: #9e9e9e;
$yellow: #ffce00;
$red: #e7593a;
$blue: #005bbb;
$dark-grey: #1c1c1c;
$dense-balck: #0d0d0d;
$black: #000;

// font size
$base: 16px;

// breapoints
$breakpoints: (
  "xs": 0,
  "sm": 600px,
  "md": 900px,
  "lg": 1200px,
  "xl": 1536px,
);

// transition
$transition-duration: 0.5s;
$transition-func: ease-in-out;
