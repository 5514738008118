@import "../../styles/variables";
@import "../../styles/mixins/respond-to";
@import "../../styles/mixins/px-to-rem";

.root {
  align-items: flex-start;
  border-top: 2px solid $white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 46px 0;

  @include respond-to("lg") {
    align-items: center;
  }
}

.links {
  align-items: center;
  display: flex;
  flex-direction: column;

  @include respond-to("lg") {
    flex-direction: row;
  }
}

.link {
  font-family: Poppins, sans-serif;
  font-size: px-to-rem(18px);
  font-weight: 600;
  margin-bottom: 40px;
  transition: color $transition-duration $transition-func;

  @include respond-to("lg") {
    margin: 0 30px;
  }

  a {
    color: $white;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.link:hover {
  color: $secondary;
}
