@import "../../styles/variables";
@import "../../styles/mixins/px-to-rem";

.overlay {
  align-items: center;
  background-color: rgb(0 0 0 / 75%);
  display: flex;
  inset: 0;
  justify-content: center;
  opacity: 0;
  position: fixed;
  transition: opacity 300ms ease-in-out;
  z-index: 10;
}

.overlay-after-open {
  opacity: 1;
}

.overlay-before-close {
  opacity: 0;
}

.content {
  background-color: $black;
  border: solid 1px $white;
  border-radius: 15px;
  padding: 15px;
  transform: rotate3d(1, 0, 0, 90deg);
  transition: transform 300ms ease-out;
  vertical-align: middle;

  .heading {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;

    .title {
      font-size: px-to-rem(20px);
      margin-bottom: 20px;
      text-align: center;
    }

    .close-btn {
      color: $white;
      cursor: pointer;
      margin-left: 20px;
      transition: color $transition-duration $transition-func;

      &:hover {
        color: $secondary;
      }
    }

    .close-icon {
      cursor: pointer;
      fill: $white;
      margin-left: 20px;
      transition: fill $transition-duration $transition-func;

      &:hover {
        fill: $secondary;
      }
    }
  }

  .description {
    font-size: px-to-rem(14px);
  }
}

.content-after-open {
  transform: rotate3d(0, 0, 0, 90deg);
}

.content-before-close {
  transform: rotate3d(1, 0, 0, 90deg);
}
