@import "../../styles/variables";

.blue {
  fill: $blue;
}

.red {
  fill: $red;
}

.yellow {
  fill: $yellow;
}
