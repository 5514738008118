@import "../../styles/variables";
@import "../../styles/mixins/respond-to";
@import "../../styles/mixins/px-to-rem";

.root {
  width: 100%;

  .block {
    display: flex;
    flex-direction: column;
    margin-bottom: 125px;

    @include respond-to("lg") {
      flex-direction: row;
      margin-bottom: 56px;
    }
  }

  .heading {
    font-family: Poppins, sans-serif;
    font-size: px-to-rem(16px);
    font-weight: 500;
    margin-bottom: 18px;
    text-align: center;
    text-transform: uppercase;

    @include respond-to("sm") {
      margin-bottom: 30px;
    }

    @include respond-to("lg") {
      flex-basis: 25%;
      margin-bottom: 0;
      text-align: left;
    }
  }

  .description {
    display: flex;
    flex-direction: row;

    @include respond-to("lg") {
      flex-basis: 75%;
    }

    .icon {
      flex-shrink: 0;
      margin-right: 16px;
    }
  }

  .color-text {
    font-family: Poppins, sans-serif;
    font-size: px-to-rem(22px);
    font-weight: 500;
    margin-bottom: 16px;
    text-transform: uppercase;

    @include respond-to("lg") {
      font-size: px-to-rem(24px);
    }

    &.yellow {
      color: $yellow;
    }

    &.blue {
      color: $blue;
    }
  }

  .text {
    font-family: Poppins, sans-serif;
    font-size: px-to-rem(16px);
    font-weight: 400;
  }
}
